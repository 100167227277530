import axios from 'axios'
import { useToast } from "primevue/usetoast";
const http = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}`,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
})
http.defaults.timeout = 10000
http.interceptors.request.use(
  config => {
    const token = localStorage.getItem("token")
    if (token) {
      config.headers.common["Authorization"] = `Bearer ${token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
http.interceptors.response.use(
  response => {
    if (response.status === 200 || response.status === 201) {
      const data = response.data.status
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
error => {
  console.log('----',error.response)
    if (error.response.status) {
      console.log(error.response.status)
      switch (error.response.status) {
        case 400:
          break
        case 401:
          console.log(error.response.config.url)
          if (error.response.config.url == 'me') {
         //   window.location.href = '/login'
          }  if (error.response.config.url == 'login') {
          //  window.location.href= '/login'
          }  else {
            Toastify({
              text: 'Im Sorry. You are not authenticated, Please try logging again....',
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: "bottom",
              position: "center",
              stopOnFocus: false,
              style: {
                background: "#dc3545",
              }
            }).showToast();
          }
          break
        case 403:
           break
        case 404:
          break
        case 500:
          let txt = 'Unable to connect to server, Please try again...'
          if (error.response.config.url == 'me') {
              window.location.href= '/login'
          } else {
            Toastify({
              text: txt,
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: "bottom",
              position: "left",
              stopOnFocus: false,
              style: {
                background: "#dc3545",
              }
            }).showToast();
          }
          break
        case 502:
          window.location.href= '/login'
          break
      }
      return Promise.reject(error.response)
    }
  }
)
export default  http
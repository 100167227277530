export default {
    namespaced: true,
    state: {
      view: 'list',
      class_list: [],
      class_thumb: '',
      class_upload_thumb: '',
      class_library_thumb: '',
     },
    getters: {
      GET_CLASS_THUMB(state) {
        return state.class_thumb
      },
      GET_CLASS_UPLOAD_THUMB(state) {
        return state.class_upload_thumb 
      },
      GET_CLASS_LIBRARY_THUMB(state) {
        return state.class_library_thumb
      }
    },
    mutations: {
      SET_CLASS_THUMB: (state, thumb) => {
        state.class_thumb =  thumb.thumbnail
      },
      SET_CLASS_UPLOAD_THUMB: (state, thumb) => {
        state.class_upload_thumb = thumb.thumbnail
      },
      SET_CLASS_LIBRARY_THUMB: (state, thumb) => {
        state.class_library_thumb = thumb.thumbnail
      }
    },
    actions: {
      
    }
  };
  
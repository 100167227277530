export default {
  namespaced: true,
  state: {
    view: 'list',
    school_list: []
   },
  getters: {
    GET_VIEW(state) {
      return state.view
    },
    GET_SCHOOL_LIST(state) {
      return state.school_list
    },
  },
  mutations: {
    CREATE_SCHOOLS: (state, view)  =>  {
        state.view = view
    },
    SET_SHOOLS_LIST: (state, list) => {
      state.school_list =  list
    }
  },
  actions: {
    
  }
};

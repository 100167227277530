export default {
    namespaced: true,
    state: {
      question_list: []
     },
    getters: {
      GET_QUESTION_LIST(state) {
        return state.question_list
      },
    },
    mutations: {
        SET_QUESTION_LIST: (state, list) => {
        state.question_list =  list
      }
    },
    actions: {
      
    }
  };
  
import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/store';

import constant from '@/utils/Constant.js'
import http from '@/common/Http.js'

const PUBLIContainer = () => import('./views/containers/PUBLIContainer.vue')
const ADMINContainer = () => import('./views/containers/ADMINContainer.vue')



const Auth = () => import('./views/auth/Auth.vue')
const ForgotPassword = () => import('./views/auth/ForgotPassword.vue')
const ResetPassword = () => import('./views/auth/ResetPassword.vue')


const Admin = () => import('./views/admin/Admin.vue')
const Dashboard = () => import('./views/dashboard/Dashboard.vue')
const Schools = () => import('./views/schools/Schools.vue')
const Students = () => import('./views/students/Students.vue')
const Teachers = () => import('./views/teachers/Teachers.vue')

const Survey = () => import('./views/survey/Survey.vue')
const Questions = () => import('./views/questions/Questions.vue')
const Class = () => import('./views/class/Class.vue')
const Report = () => import('./views/report/Report.vue')
const SurveyPublic = () => import('./views/survey/SurveyPublic.vue')


const CSVDownload = () => import('./views/csv/CSVDownload.vue')
const CSVUpload = () => import('./views/csv/CSVUpload.vue')
const SchoolYear = () => import('./views/school-year/SchoolYear.vue')

const Terms = () => import('./views/terms/Terms.vue')

const Year = () => import('./views/year/Year.vue')

const Calendar = () => import('./views/calendar/Calendar.vue')

const Onboarding = () => import('./views/onboarding/Onboarding.vue')

const canAccess = (to, from, next) => {
  if(from.name != 'Login') {
    store.dispatch('UserModule/GET_SELF', [constant, http,  to.name])
    .then(resp => {
      let status = resp.data.status
      if (status == 0) {
        next({ name: 'Login' })
      } else next()
    }).catch(err => {
    });
  } else next()
}


const routes = [
  {
    path: '/',
    component: PUBLIContainer,
    children: [
        {
            path: '/',
            name: 'Auth',
            component: Auth
        },
        {
            path: '/login',
            name: 'Login',
            component: Auth
        },
        {
          path: '/forgot/password',
          name: 'Forgot Password',
          component: ForgotPassword
        },
        {
          path: '/reset/password',
          name: 'Reset Password',
          component: ResetPassword
        },
        {
          path: '/q/:survey_id?',
          name: 'Public Survey',
          component: SurveyPublic
        },
        {
          path: '/onboarding',
          name: 'Onboarding',
          component: Onboarding
      },
    ]
  },
  {
    path: '/',
    component: ADMINContainer,
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        beforeEnter: canAccess
      }, 
      {
        path: '/admin',
        name: 'School List',
        component: Admin,
        beforeEnter: canAccess
      }, 
      {
        path: '/schools',
        name: 'Schools',
        component: Schools,
        beforeEnter: canAccess
      },
      {
        path: '/teachers',
        name: 'Teachers',
        component: Teachers,
        beforeEnter: canAccess
      }, 
      {
        path: '/students/:class_id?',
        name: 'Students',
        component: Students,
        beforeEnter: canAccess
      },
      {
        path: '/class',
        name: 'Class',
        component: Class,
        beforeEnter: canAccess
      },
      {
        path: '/survey',
        name: 'Survey',
        component: Survey,
        beforeEnter: canAccess
      },
      {
        path: '/questions',
        name: 'Questions',
        component: Questions,
        beforeEnter: canAccess
      },
      {
        path: '/report',
        name: 'Report',
        component: Report,
        beforeEnter: canAccess
      },
      {
        path: '/csv/upload',
        name: 'Upload CSV',
        component: CSVUpload,
        beforeEnter: canAccess
      },
      {
        path: '/terms',
        name: 'Terms',
        component: Terms,
        beforeEnter: canAccess
      }, 
      {
        path: '/year',
        name: 'Year',
        component: Year,
        beforeEnter: canAccess
      }, 
      {
        path: '/calendar',
        name: 'Calendar',
        component: Calendar,
        beforeEnter: canAccess
      }, 
    
      {
        path: '/csv/download',
        name: 'Download CSV',
        component: CSVDownload,
        beforeEnter: canAccess
      }, 
      {
        path: '/school/year',
        name: 'School Year',
        component: SchoolYear,
        beforeEnter: canAccess
      }, 
    ]
  }
]

const router = createRouter({ 
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router

class Utils {
    constructor() {
        if(!Utils.instance) {
            Utils.instance = this
        }
        return Utils.instance
    }
    getMimeType(file, fallback = null) {
        const byteArray = (new Uint8Array(file)).subarray(0, 4);
        let header = '';
        for (let i = 0; i < byteArray.length; i++) {
           header += byteArray[i].toString(16);
        }
        switch (header) {
            case "89504e47":
                return "image/png";
            case "47494638":
                return "image/gif";
            case "ffd8ffe0":
            case "ffd8ffe1":
            case "ffd8ffe2":
            case "ffd8ffe3":
            case "ffd8ffe8":
                return "image/jpeg";
            default:
                return fallback;
        }
    }
    getRandomNumbers() {
        return Math.floor(Math.random() * 50) + 1
    }
    generatePassword() {
        var generatePassword = (
            length = 8,
            wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$'
          ) =>
            Array.from(crypto.getRandomValues(new Uint32Array(length)))
              .map((x) => wishlist[x % wishlist.length])
              .join('')
         return generatePassword() 

    }
}
const instane = new Utils()
Object.freeze(instane)
export default instane
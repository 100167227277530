export default {
  namespaced: true,
  state: {
    user : {},
    token: localStorage.getItem('token') || '',
    school_year: [],
    user_thumb: '',
    user_upload_thumb: '',
    user_library_thumb: '',
    teacher: [],
    path: ''
  },
  getters: {
    GET_USER(state) {
      return state.user
    },
    GET_TEACHER(state) {
      return state.teacher
    },
    GET_ACTIVE_SCHOOL_YEAR(state) {
      return state.school_year
    },
    GET_USER_THUMB(state) {
      return state.user_thumb
    },
    GET_USER_UPLOAD_THUMB(state) {
      return state.user_upload_thumb
    },
    GET_USER_LIBRARY_THUMB(state) {
      return state.user_library_thumb
    },
    GET_PATH(state) {
      return state.path
    },
  },
  mutations: {
    AUTH_LOGIN: (state, data) => {
        state.token = data[1]
        state.user = data[0].user
        state.teacher = data[0].teacher
        state.school_year = data[0].school_year
    },
    SET_ACTIVE_SCHOOL_YEAR: (state, a)  =>  {
      state.school_year = a
    },
    SET_USER_THUMB: (state, thumb) => {
      state.user_thumb =  thumb.thumbnail
      state.path =  thumb.path
    },
    SET_USER_UPLOAD_THUMB: (state, thumb) => {
      state.user_upload_thumb = thumb.thumbnail
      state.path =  thumb.path
    },
    SET_USER_LIBRARY_THUMB: (state, thumb) => {
      state.user_library_thumb = thumb.thumbnail
      state.path =  thumb.path

    },
    
  },
  actions: {
    AUTH_LOGIN({commit}, [params, constant, http]) {

      return new Promise((resolve, reject) => {
        http.post(constant.login, params).then(resp => {       
           const  response = resp.data;
           const status = response.status;
           if (status == 1) {
              const data = response.data
              localStorage.setItem('token', data.token)
              commit('AUTH_LOGIN', [data, data.token])
           }
          resolve(resp)
        })
        .catch(err => {
           reject(err)
        })
      })
    },
    async GET_SELF({commit}, [ constant, http, path ]) {
      try {
        const f = new URLSearchParams()
        f.append('path', path)
        const response = await http.post(constant.me, f)
        
        let status = response.data.status
        if (status == 0) {
          localStorage.removeItem('token')
        } else 
          commit('AUTH_LOGIN', [response.data, localStorage.getItem('token')])
        return response
     } catch (err) {
     }
    },
    async LOGOUT({commit}, [ constant, http, path ]) {
      try {
        const f = new URLSearchParams()
        f.append('path', path)
        const response = await http.post(constant.LOGOUT, f)
        return response
     } catch (err) {
     }
    }
  }
};

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store/store'

import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice';
import Tooltip from 'primevue/tooltip';

const app = createApp(App)
app.use(router)
app.use(ToastService)
app.use(ConfirmationService)
app.use(PrimeVue)
app.use(store)
app.directive('tooltip', Tooltip)
app.mount('#app') 

import 'element-plus/dist/index.css'
import '@/assets/themes/assets/plugins/global/plugins.bundle.css'
import '@/assets/themes/assets/plugins/custom/prismjs/prismjs.bundle.css'
import '@/assets/themes/assets/css/style.bundle.css'
import '@/assets/themes/assets/css/themes/layout/header/base/light.css'
import '@/assets/themes/assets/css/themes/layout/header/menu/light.css'
import '@/assets/themes/assets/css/themes/layout/brand/dark.css'
import '@/assets/themes/assets/css/themes/layout/aside/dark.css'
import '@/assets/themes/assets/css/pages/wizard/wizard-5.css'
import '@/assets/css/styles.scss'
export default {
    namespaced: true,
    state: {
      view: 'list',
      survey_list: [],
      suvey_thumb: '',
      survey_social_thumb: '',
      survey_upload_thumb: '',
      survey_library_thumb: ''
     },
    getters: {
      GET_SURVEY_THUMB(state) {
        return state.suvey_thumb
      },
      GET_SURVEY_SOCIAL_THUMB(state) {
        return state.survey_social_thumb
      },
      GET_SURVEY_UPLOAD_THUMB(state) {
        return state.survey_upload_thumb 
      },
      GET_SURVEY_LIBRARY_THUMB(state) {
        return state.survey_library_thumb
      }
    },
    mutations: {
      SET_SURVEY_THUMB: (state, thumb) => {
        state.suvey_thumb =  thumb.thumbnail
      },
      SET_SURVEY_SOCIAL_THUMB: (state, thumb) => {
        state.survey_social_thumb =  thumb.social_thumb
      },
      SET_SURVEY_UPLOAD_THUMB: (state, thumb) => {
        state.survey_upload_thumb = thumb.thumbnail
      },
      SET_SURVEY_LIBRARY_THUMB: (state, thumb) => {
        state.survey_library_thumb = thumb.thumbnail
      },
    },
    actions: {
      
    }
  };
  
export default {
  namespaced: true,
  state: {
   view: 'list',
   teacher_list: []
  },
  getters: {
    GET_VIEW(state) {
      return state.view
    },
    GET_TEACHER_LIST(state) {
      return state.teacher_list
    }
  },
  mutations: {
    ADD_TEACHER: (state, view)  =>  {
        state.view = view
    },
    SET_TEACHER_LIST: (state, list) => {
      state.teacher_list =  list
    }

  },
  actions: {

  }
};

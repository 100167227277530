
import { createStore } from 'vuex'
import UserModule from './modules/users';
import SchoolsModule from './modules/schools';
import TeachersModule from './modules/teachers';
import QuestionsModule from './modules/questions';
import SchoolYearModule from './modules/school-year';
import SurveyModule from './modules/survey';
import ClassModule from './modules/class';
import StudentsModule from './modules/students';
import YearsModule from './modules/year';



const store = createStore({
    modules: {
            UserModule,
            SchoolsModule,
            TeachersModule,
            QuestionsModule,
            SchoolYearModule,
            SurveyModule, 
            ClassModule,
            StudentsModule,
            YearsModule
        }
    })

export default store;

<template>
  <div>
    <router-view />
    <Toast position="bottom-left"></Toast>
  </div>
</template>
<script>
import Toast from 'primevue/toast';
import { defineComponent, provide } from 'vue'
import Constant from '@/utils/Constant.js'
import Utils from '@/utils/Utils.js'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import Http from '@/common/Http.js'
import AppRouter from '@/common/Router.js'
import { useToast } from "primevue/usetoast";
export default defineComponent({
  components: { Toast },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const constants = Constant
    const store = new useStore()
    const http = Http
    const appRouter = AppRouter
    const toast = useToast()
    const utils = Utils
    provide('App', { utils, toast, appRouter, router, route, constants, http, store })
  },
})
</script>

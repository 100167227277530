export default {
    public_path: '',
    api_url : window.location.hostname,
    api_protocol: window.location.protocol,
    api_full_url: window.location.origin,
    WEB_URL: 'http://127.0.0.1:8000/',
    CLASS_THUMBNAIL_PATH: 'thumbnail/class/',
    STUDENTS_THUMBNAIL_PATH: 'thumbnail/students/',
    USERS_THUMBNAIL_PATH: 'thumbnail/users/',
    SURVEY_THUMBNAIL_PATH: 'thumbnail/survey/thumb/',
    SURVEY_SOCIAL_THUMBNAIL_PATH: 'thumbnail/survey/social-thumb/',

    header: {'Content-Type': 'application/x-www-form-urlencoded'},
    login: 'login',
    LOGOUT: 'logout',
    me: 'me',
    usersgetlist: 'usersgetlist',
    USERS_FORGOT_PASSWORD: 'user/forgot-password',
    USERS_NEW_PASSWORD: 'user/new-password',
    SCHOOLS_CREATE: 'schools/create',
    SCHOOLS_DELETE: 'schools/delete',
    SCHOOLS_EXPORT: 'schools/exports',
    SCHOOLS_EDIT: 'schools/edit',
    SCHOOLS_ONBOARDING: 'schools/onboarding',
    schoolsgetlist: 'schools/list',
    teachersgetlist: 'teachers/list',
    studentsgetlist: 'students/list',
    TEACHERS_DELETE: 'teachers/delete',
    TEACHERS_ADD: 'teachers/add',
    TEACHERS_EDIT: 'teachers/edit',
    TEACHERS_EMAIL_CREDENTIALS: 'teachers/email-credentials',
    

    QUESTIONS_ADD: 'questions/add',
    QUESTIONS_LIST: 'questions/list',
    QUESTIONS_DELETE: 'questions/delete',
    QUESTIONS_EXPORT: 'questions/exports',

    SURVEY_EDIT: 'survey/edit',
    SURVEY_CREATE: 'survey/create',
    SURVEY_LIST: 'survey/list',
    SURVEY_DELETE: 'survey/delete',
    SURVEY_UPDATE_QUESTIONS: 'survey/update-questions',
    SURVEY_ACTIVATE: 'survey/activate',
    SURVEY_GET_DEFAULT: 'survey/get-default',
    SURVEY_SAVE_ANSWER: 'survey/save-answer',
    SURVEY_GET_REPORT_BY_TERM_AND_WEEKNO: 'survey/get-report-by-term-and-weekno',
    SURVEY_GET_STUDENT_LIST_BY_ANSWERS: 'survey/get-student-list-by-answer',
    SURVEY_GET_REPORT_BY_SURVEY_ID: 'survey/get-survey-report-by-survey-id',
    SURVEY_GET_RESPONDENT: 'survey/get-respondent',
    SURVEY_GET_RESPONDENT_SURVEY_RESULTS: 'survey/get-respondent-survey-results',
    SURVEY_GET_REPORT: 'survey/get-survey-reports',
    SURVEY_SHARE_REPORT: 'survey/share-report',


    CLASS_LIST: 'class/list',
    CLASS_DELETE: 'class/delete',
    CLASS_EDIT: 'class/update-class',
    CLASS_CREATE: 'class/create',
    CLASS_EXPORT: 'class/exports',

    STUDENT_ADD: 'students/add',
    STUDENT_EDIT: 'students/edit',
    STUDENT_LIST: 'students/list',
    STUDENT_DELETE: 'students/delete',
    STUDENT_GET_INFO: 'students/get-info',
    STUDENT_LIS_BY_CLASS: 'students/get-student-by-class',
    STUDENT_EXPORT: 'students/exports',
    STUDENT_IMPORT: 'students/import',
    STUDENT_GET_WELLBEING_DATA: 'students/get-wellbeing-chart-data',



    TERM_ADD: 'term/add',
    TERM_LIST: 'term/list',
    TERM_GET_BY_ID: 'term/get-by-id',
    TERM_DELETE_WEEK: 'term/delete-week',

    SCHOOLS_YEAR_GET_LIST: 'school-year/list',
    SCHOOL_YEAR_CREATE: 'school-year/create',
    SCHOOL_YEAR_EDIT: 'school-year/edit',
    SCHOOL_YEAR_DELETE: 'school-year/delete',
    SCHOOL_YEAR_GET_ACTIVE: 'school-year/get-active',

    SEARCH_PEXELS_IMAGE: 'image/search',
    UPLOAD_IMAGE: 'image/upload',
    IMAGE_LIBRARY_GET_LIST: 'image/get-image-library',

    DASHBOARD_GET_DATA: 'dashboard/get',
    DASHBOARD_GET_CHART_DATA: 'dashboard/get-chart-data',
    DASHBOARD_GET_MISSING_CONNECTIONS: 'dashboard/get-missing-connections',
    DASHBOARD_GET_WELLBEING_DATA: 'dashboard/get-wellbeing-chart-data',

    NOTES_ADD: 'notes/add',
    NOTES_GET_LIST: 'notes/list',
    NOTES_DELETE: 'notes/delete',

    YEAR_ACTIVATE: 'years/activate',
    YEAR_GET_LIST: 'years/list'
}

//'https://api.kinnections.linneodigital.com/'

class Router {
    constructor() {
      if (!Router.instance) {
        Router.instance = this;
      }
      return Router.instance;
    }
    routeTo(route, router, name) {
      router.push({
        name: name,
        query: {
          ...route.query,
        },
      })
    }
}
const instance = new Router();
Object.freeze(instance);
export default instance;


export default {
    namespaced: true,
    state: {
     view: 'list',
     yearList: [],
     activeYear: ''
    },
    
    getters: {
      GET_ACTIVE_YEAR(state) {
        return state.activeYear
      },
      GET_YEAR_LIST(state) {
        return state.yearList
      }
    },

    mutations: {
      SET_YEAR_LIST: (state, list) => {
        state.yearList =  list
      },

      SET_ACTIVE_YEAR: (state, year) => {
        state.activeYear =  year
      }
  
    },

    actions: {
  
    }
  };
  
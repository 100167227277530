export default {
    namespaced: true,
    state: {
      active_school_year: [],
      school_year_list: []
     },
    getters: {
      GET_SCHOOL_YEAR_LIST(state) {
        return state.school_list
      },
    },
    mutations: {
      SET_ACTIVE_SCHOOL_YEAR: (state, a)  =>  {
          state.active_school_year = a
      },
      SET_SHOOL_YEAR_LIST: (state, list) => {
        state.school_year_list =  list
      }
    },
    actions: {
      
    }
  };
  
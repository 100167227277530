export default {
    namespaced: true,
    state: {
      view: 'list',
      student_list: [],
      student_thumb: '',
      student_library_thumb: '',
      student_upload_thumb: '',
      path: ''
     },
    getters: {
      GET_PATH(state) {
        return state.path
      },
      GET_STUDENT_THUMB(state) {
        return state.student_thumb
      },
      GET_STUDENT_UPLOAD_THUMB(state) {
        return state.student_upload_thumb
      },
      GET_STUDENT_LIBRARY_THUMB(state) {
        return state.student_library_thumb
      }
    },
    mutations: {
      SET_STUDENT_THUMB: (state, thumb) => {
        state.student_thumb =  thumb.thumbnail
        state.path =  thumb.path

      },
      SET_STUDENT_UPLOAD_THUMB: (state, thumb) => {
        state.student_upload_thumb = thumb.thumbnail
        state.path =  thumb.path
      },
      SET_STUDENT_LIBRARY_THUMB: (state, thumb) => {
        state.student_library_thumb = thumb.thumbnail
        state.path =  thumb.path
      },
      SET_PATH: (state, path) => {
        state.path = path
      }
    },
    actions: {
    }
  };
  